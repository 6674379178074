import { Button } from '@consta/uikit/Button'
import styles from './GroupFields.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { CommonFieldProps } from '../../types/props'
import { FilterBlock, Modal } from '../../../../../components'
import { generateFields, IField, IFieldValue } from '../../../../shared/helpers/fields'
import { generateFieldsValuesState } from '../../../../shared/helpers/generateFieldsValue'
import { FieldType } from '../../../../../components/FilterBlock/types'
import { getFieldsStateValueByType } from '../../../../pages/DocumentForm/ui/MainPage/utils'
import cx from 'classnames'

interface GroupFieldProps extends Omit<CommonFieldProps, 'onChange' | 'value'> {
  title: string,
  fields: IField[],
  value: IFieldValue[]
  editMode?: boolean
  readonly?: boolean
  onSubmit: (data: IFieldValue[]) => void
}


export const GroupField = (props: GroupFieldProps) => {
  const {
    label,
    title,
    fields = [],
    value, // внешний стейт для данных
    editMode,
    readonly,
    onSubmit,
    isRequired
  } = props


  const [formOpen, setFormOpen] = useState<boolean>(false)

  const handleCancel = () => {
    setFormOpen(false)
  }
  const handleSubmit = (fieldValues: IFieldValue[]) => {
    onSubmit(fieldValues)
    setFormOpen(false)
  }

  return (
    <>
      {label &&
        <label className={cx(styles.label, { [styles.requiredLabel]: isRequired })}>
          {label}
        </label>
      }
      <Button
        view={'ghost'}
        label={'Открыть форму'}
        onClick={() => setFormOpen(true)}
        disabled={!fields?.length}
      />
      {
        formOpen ? (
          <Modal
            isOpen
            withClose
            hasOverlay
            size={'min'}
            onClickOutside={() => setFormOpen(false)}
            onClose={() => setFormOpen(false)}
            title={`Форма ${title}`}
            subtitle={' '}
          >
            <GroupFieldForm
              editMode={editMode}
              readonly={readonly}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              fields={fields}
              fieldValues={value}
            />
          </Modal>
        ) : null
      }
    </>
  )
}

interface GroupFieldFormProps {
  editMode?: boolean
  readonly?: boolean
  handleCancel: () => void
  handleSubmit: (values: IFieldValue[]) => void
  fields: IField[],
  fieldValues: IFieldValue[]
}

export const GroupFieldForm = (props: GroupFieldFormProps) => {
  const {
    editMode,
    handleCancel,
    handleSubmit,
    fields,
    fieldValues,
  } = props

  const [localFieldValues, setLocalFieldValues] = useState<IFieldValue[]>([])

  useEffect(() => {
    setLocalFieldValues(fieldValues || [])
  }, [fieldValues])


  const generatedFields = useMemo(() =>
      generateFields(fields, localFieldValues, editMode),
    [fields, editMode])

  const fieldsState = useMemo(() =>
      generateFieldsValuesState(fields, localFieldValues),
    [localFieldValues])

  const handleChangeFieldsState = (fieldId: string, value: any, type: FieldType) => {
    /** Обновляем черновик полей */
    const fieldValueByType = getFieldsStateValueByType(Number(fieldId), value, type)
    setLocalFieldValues(prev => {
      const stateFieldValueIndex = prev.findIndex(fieldValue => `${fieldValue.id}` === `${fieldId}`)
      if (stateFieldValueIndex >= 0) {
        prev.splice(stateFieldValueIndex, 1, fieldValueByType)
        return prev.slice()
      }
      return [...prev, fieldValueByType]
    })
  }

  const handleClickCancel = () => {
    handleCancel()
    setLocalFieldValues(fieldValues)
  }

  const handleClickSubmit = () => {
    handleSubmit(localFieldValues)
  }


  return (
    <>
      <FilterBlock
        visible={true}
        visibleFilterView={false}
        filterRowsFields={generatedFields}
        filterStateDraft={fieldsState}
        onChangeFilterDraft={handleChangeFieldsState}
        disabled={!editMode}

        /* Рудимент, который устранится, после разделения на отдельный генератор
        * полей и фильтров */
        filterState={{}}
        onChangeFilter={() => {
        }}
      />
      <div className={styles.buttons}>
        <Button
          label={'Отменить'}
          view={'ghost'}
          onClick={handleClickCancel}
        />
        <Button
          label={'Принять'}
          onClick={handleClickSubmit}
        />
      </div>
    </>
  )
}