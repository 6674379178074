export const PUBLIC_PATHS = {
  AUTH: '/auth',
}

export const PRIVATE_PATHS = {
  OPERATION: '/auth',
  ORDER_SCAN: '/order/scan/:selectValue?',
  ORDER_FORM: '/order/:orderId',
  ORDER_FORM_2: '/order-v2/:orderId',
  ORDER_CLOSED: '/order/closed/:orderId',
  SETTINGS: '/order-settings',
  ACCEPTANCE_SCAN: '/acceptance/scan',
  ACCEPTANCE_FORM: '/acceptance/:supplyId',
  ACCEPTANCE_FORM_TSD: '/acceptance-tsd/:page?/:supplyId?',

  CROSSDOCKING_SORTING: '/crossdocking-sorting/:page?/:supplyId?',

  ASSEMBLING_PHYSICAL_SET: '/assembling-physical-set/:page?/:docId?',

  RESUME_ORDER_FORM: '/order/resume/:orderId',
  REGISTRY_SCAN: '/register/scan',
  REGISTRY_FORM: '/register/:registerId',

  WAREHOUSE_CONTROL_SCAN: '/warehouse_control/scan/:controlId?',
  WAREHOUSE_CELL_CONTROL_FORM: '/warehouse_control_cell/:cellBarcode',
  WAREHOUSE_SKU_CONTROL_FORM: '/warehouse_control_sku/:skuBarcode',

  F_OF_PLACES_LIST: `/formation-of-places/:page`,
  F_OF_TRANSPORT_PLACES_LIST: `/formation-of-transport-places/:page?`,

  BATCH_GENERATION: `/batch-generation/:page?/:id?`,
  BATCH_GENERATION_PHYSICAL_SET: `/batch-generation-physical-set/:page?/:id?`,

  MARKING_TRANSPORT_PLACE: '/marking-transport-place',

  PRODUCT_PLACEMENT: '/product-placement/:page',

  SHIPMENT_FROM_WAREHOUSE_SCAN: '/shipment-from-warehouse/scan',
  SHIPMENT_FROM_WAREHOUSE_EDIT: '/shipment-from-warehouse/:docId/edit',
  SHIPMENT_FROM_WAREHOUSE_LOG: '/shipment-from-warehouse/:docId/log',
  SHIPMENT_FROM_WAREHOUSE_TRAFFIC: '/shipment-from-warehouse/:docId/traffic',
  SHIPMENT_ORDER_LIST: '/operations-and-documents/ORDER__LIST',
  REPORTS: '/reports/:page?',

  OPERATIONS_AND_DOCUMENTS:
    '/operations-and-documents/:page?/:id?/:tabId?/:serviceId?',

  REFUND_ACCEPTANCE: `/refund-acceptance/:page?/:id?`,
  GOODS_RELOCATION: '/goods-relocation',

  DIRECTORIES: '/directories/:page?/:section?/:type?/:id?',

  BATCH_SORTING: '/batch-sorting',
  BATCH: '/operations-and-documents/ORDER_BATCH__LIST',

  ARM_PRINTING: '/arm-printing/:page?',

  GOODS_PICKING_TSD: '/goods-picking-tsd/:page?/:docId?/:cellId?',
  GOODS_PICKING: '/goods-picking/:page?/:docId?',

  BILLING: '/operations-and-documents/BILLING__LIST',
  SERVICES_RENDERED: '/operations-and-documents/SERVICE__LIST',

  CARRIER_COMPANIES: '/operations-and-documents/carrierCompany__LIST',

  SUPPLY_PLAN: '/operations-and-documents/SUPPLY_PLAN__LIST',
  RECEIPT__LIST: '/operations-and-documents/RECEIPT__LIST',
  PLACING__LIST: '/operations-and-documents/PLACING__LIST',

  INVENTORY_TSD: '/inventory-tsd/:page?/:docId?/:cellId?',
  PARTIAL_INVENTORY_TSD: '/partial-inventory-tsd/:page?/:cellId?',
  PACKING_INSTRUCTIONS: '/packing-instructions',
  PACKING_INSTRUCTIONS_FORM: '/packing-instructions/:page?/:docId?',
  GOODS_RELABEL_SCAN: '/goods-relabel/scan',
  GOODS_RELABEL_FORM: '/goods-relabel/:id?',

  NEW_ANALYTICS: '/new-analytics',
  DOCUMENTS_LIST: '/new-list/:docType?',
  DOCUMENT_FORM: '/new-form/:docType/:docId?',

  /*testing arm: new assembly tsd*/
  NEW_ASSEMBLY_TSD: '/new-assembly-tsd/:page?/:docId?/:cellId?'
}

export const PATHS = {
  ...PUBLIC_PATHS,
  ...PRIVATE_PATHS,
}
